define("discourse/plugins/chat/discourse/components/user-threads/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-modifier", "discourse/components/conditional-loading-spinner", "discourse/lib/is-element-in-viewport", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/chat-message-thread-indicator", "discourse/plugins/chat/discourse/components/thread-title", "discourse/plugins/chat/discourse/models/chat-channel", "discourse/plugins/chat/discourse/models/chat-thread", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _emberModifier, _conditionalLoadingSpinner, _isElementInViewport, _environment, _debounce, _decorators, _channelTitle, _chatMessageThreadIndicator, _threadTitle, _chatChannel, _chatThread, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserThreads = _exports.default = (_class = (_class2 = class UserThreads extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _defineProperty(this, "loadMore", (0, _emberModifier.modifier)(element1 => {
        this.intersectionObserver = new IntersectionObserver(this.loadThreads);
        this.intersectionObserver.observe(element1);
        return () => {
          this.intersectionObserver.disconnect();
        };
      }));
      _defineProperty(this, "fill", (0, _emberModifier.modifier)(element1 => {
        this.resizeObserver = new ResizeObserver(() => {
          if ((0, _isElementInViewport.default)(element1)) {
            this.loadThreads();
          }
        });
        this.resizeObserver.observe(element1);
        return () => {
          this.resizeObserver.disconnect();
        };
      }));
    }
    get threadsCollection() {
      return this.chatApi.userThreads(this.handleLoadedThreads);
    }
    loadThreads() {
      (0, _debounce.default)(this, this.debouncedLoadThreads, _environment.INPUT_DELAY);
    }
    async debouncedLoadThreads() {
      await this.threadsCollection.load({
        limit: 10
      });
    }
    handleLoadedThreads(result1) {
      return result1.threads.map(threadObject1 => {
        const channel1 = _chatChannel.default.create(threadObject1.channel);
        const thread1 = _chatThread.default.create(channel1, threadObject1);
        const tracking1 = result1.tracking[thread1.id];
        if (tracking1) {
          thread1.tracking.mentionCount = tracking1.mention_count;
          thread1.tracking.unreadCount = tracking1.unread_count;
        }
        return thread1;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat__user-threads-container">
        <div class="chat__user-threads" {{this.fill}}>
          {{#each this.threadsCollection.items as |thread|}}
            <div
              class="chat__user-threads__thread-container"
              data-id={{thread.id}}
            >
              <div class="chat__user-threads__thread">
                <div class="chat__user-threads__title">
                  <ThreadTitle @thread={{thread}} />
                  <ChannelTitle @channel={{thread.channel}} />
                </div>
  
                <div class="chat__user-threads__thread-indicator">
                  <ThreadIndicator
                    @message={{thread.originalMessage}}
                    @interactiveUser={{false}}
                    @interactiveThread={{false}}
                    tabindex="-1"
                  />
                </div>
              </div>
            </div>
          {{/each}}
  
          <div {{this.loadMore}}>
            <br />
          </div>
  
          <ConditionalLoadingSpinner
            @condition={{this.threadsCollection.loading}}
          />
        </div>
      </div>
    
  */
  {
    "id": "T464UfhK",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat__user-threads-container\"],[12],[1,\"\\n      \"],[11,0],[24,0,\"chat__user-threads\"],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"threadsCollection\",\"items\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"chat__user-threads__thread-container\"],[15,\"data-id\",[30,1,[\"id\"]]],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat__user-threads__thread\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"chat__user-threads__title\"],[12],[1,\"\\n                \"],[8,[32,0],null,[[\"@thread\"],[[30,1]]],null],[1,\"\\n                \"],[8,[32,1],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n\\n              \"],[10,0],[14,0,\"chat__user-threads__thread-indicator\"],[12],[1,\"\\n                \"],[8,[32,2],[[24,\"tabindex\",\"-1\"]],[[\"@message\",\"@interactiveUser\",\"@interactiveThread\"],[[30,1,[\"originalMessage\"]],false,false]],null],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n        \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n          \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@condition\"],[[30,0,[\"threadsCollection\",\"loading\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"thread\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/index.js",
    "scope": () => [_threadTitle.default, _channelTitle.default, _chatMessageThreadIndicator.default, _conditionalLoadingSpinner.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "threadsCollection", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "threadsCollection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadThreads", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleLoadedThreads", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleLoadedThreads"), _class.prototype)), _class);
});